import inputTypes from "../../../constants/form/inputTypes";

const bidsMutationFields = [
  {
    className: "col-12 mb-3",
    inputType: inputTypes.NUMBER,
    key: "amount",
    labelText: "Bid Amount",
    placeHolder: "Bid Amount",
    rules: { required: true },
  },
  {
    className: "col-12 mb-3",
    inputType: inputTypes.DATE_TIME,
    key: "expiration_date",
    labelText: "Expiration Date",
    placeHolder: "Expiration Date",
    rules: { required: true },
  },
  {
    className: "col-12 mb-3",
    inputType: inputTypes.TEXTAREA,
    key: "additional_notes",
    labelText: "Additional Notes",
    placeHolder: "Additional Notes",
    rules: { required: true },
  },
];

export default bidsMutationFields;
