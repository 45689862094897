import endpoints from "../../constants/endpoints";
import { adminApi } from "../client";

export const getOrders = async (params) => {
  return await adminApi({
    endpoint: endpoints.ORDERS,
    method: "get",
    params: { ...params },
  });
};

export const getOrderWins = async (params) => {
  return await adminApi({
    endpoint: endpoints.ORDERS,
    method: "get",
    params: { ...params, wined_order: true },
  });
};

export const selectInventory = async (data) => {
  const payload = {
    inventory: data.inventory,
    driver: data.driver,
  };
  return await adminApi({
    endpoint: `${endpoints.ORDER_CONFIRM}${data.id}/`,
    method: "post",
    data: payload,
  });
};

export const confirmFixedPriceOrder = async (data) => {
  const payload = {
    driver: data.driver,
  };
  return await adminApi({
    endpoint: `${endpoints.FIXED_PRICE_ORDER_CONFIRM}${data.id}/`,
    method: "post",
    data: payload,
  });
};

export const cancelOrder = async (data) => {
  const payload = {};
  return await adminApi({
    endpoint: `${
      data.is_fixed_price_order
        ? endpoints.CANCEL_FIXED_PRICE_ORDER
        : endpoints.CANCEL_ORDER
    }${data.id}/`,
    method: "post",
    data: payload,
  });
};
