import React, { useEffect } from 'react';
import './Modal.css'

const Modal = ({ handleModalClose, size, children, style={} }) => {
  useEffect(() => {
    // Close the modal when the Escape key is pressed
    function onKeyDown(event) {
      if (event.keyCode === 27) {
        handleModalClose();
      }
    }
    // Prevent Scrolling
    // document.body.style.overflow = 'hidden';
    document.addEventListener('keydown', onKeyDown);

    // Clear things up when unmounting this component
    return () => {
      document.body.style.overflow = 'visible';
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [handleModalClose]);

  return (
    <div className="custom__modal__backdrop" id="modal">
      <div className='custom__modal' onClick={handleModalClose}>
        <div className={`${size || 'sm'} modal__top scale-up-center modal__container`} style={style} onClick={(e) => e.stopPropagation()}>
          <button className='modal__close btn-close' onClick={handleModalClose}/> 
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;