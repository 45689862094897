import inputTypes from "../../../constants/form/inputTypes";
import { getInventoriesForDropdown } from "../../../services/query/inventory";
import { getDriversForDropdown } from "../../../services/query/users";

export const orderDriverInventorySelectFields = [
  {
    className: "col-12 mb-3",
    inputType: inputTypes.SELECT,
    lookupQuery: getInventoriesForDropdown,
    key: "inventory",
    labelText: "Inventory",
    rules: { required: true },
  },
  {
    className: "col-12 mb-3",
    inputType: inputTypes.SELECT,
    lookupQuery: getDriversForDropdown,
    key: "driver",
    labelText: "Driver",
    rules: { required: true },
  },
];
