import React from "react";
import ListView from "../../components/listvVew/ListView";
import PageHeader from "../../components/pageHeader/PageHeader";
import { HOME_PATH, SERVICE_LOCATION_MANAGEMENT } from "../../constants/route";
import getServiceLocationTableMeta from "../../configs/meta/listMeta/serviceLoationTableMeta";
import { searchFilterMeta } from "../../configs/meta/listFilterMeta/searchFilterMeta";

const OrderManagement = () => {
  return (
    <>
      <PageHeader
        title={"Service Location Management"}
        breadCrumbItems={[
          { title: "Dashboard", path: HOME_PATH },
          { title: "Service Locations", path: SERVICE_LOCATION_MANAGEMENT },
        ]}
      />
      <div className="row">
        {/* <TableBuilder/> */}
        <ListView
          title={"Service Location List"}
          filters={searchFilterMeta}
          meta={getServiceLocationTableMeta()}
        />
      </div>
    </>
  );
};

export default OrderManagement;
