import inputTypes from "../../../constants/form/inputTypes";
import { getStatesWithId } from "../../../services/query/company";
import {
  getCategoriesForDropdown,
  getSubCategoriesForDropdown,
} from "../../../services/query/inventory";
const companySettingsFields = [
  {
    inputType: inputTypes.LIST_INPUT,
    key: "amenities_bn",
    labelText: "List Input",
    className: "mb-1 row",
    fields: [
      {
        className: "col-6 mb-3",
        inputType: inputTypes.SELECT,
        lookupQuery: getCategoriesForDropdown,
        key: "service_category",
        labelText: "Service Category",
        rules: { required: true },
      },
      {
        className: "col-6 mb-3",
        inputType: inputTypes.SELECT,
        lookupQuery: getSubCategoriesForDropdown,
        key: "service_sub_category",
        labelText: "Service Sub Categories",
        // dependencies: ["service_category"],
        rules: { required: true },
        //   onDependencyValueChange: (
        //     { service_category },
        //     key,
        //     { performLookupQuery }
        //   ) => {
        //     performLookupQuery?.({ category: service_category });
        //   },
      },
      {
        className: "col-6 mb-3",
        inputType: inputTypes.SELECT,
        isMulti: true,
        lookupQuery: getStatesWithId,
        key: "states",
        labelText: "States",
        rules: { required: true },
      },
    ],
  },
];
export default companySettingsFields;
