import endpoints from "../../constants/endpoints";
import { adminApi } from "../client";

export const getServiceLocations = async (params) => {
  return await adminApi({
    endpoint: endpoints.SERVICE_LOCATION,
    method: "get",
    params: { ...params },
  });
};

export const createServiceLocation = async (data) => {
  return await adminApi({
    endpoint: endpoints.SERVICE_LOCATION,
    method: "post",
    data: data,
  });
};

export const updateServiceLocation = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.SERVICE_LOCATION}${data.id}/`,
    method: "patch",
    data: data,
  });
};
