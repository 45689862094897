import ModalFormOpener from "../../../components/actions/ModalFormOpener";
import {
  createServiceLocation,
  getServiceLocations,
  updateServiceLocation,
} from "../../../services/query/serviceLocation";
import serviceLocationMutationFields from "../mutationMeta/serviceLocationMutationFields";
import updatableServiceLocationMutationFields from "../mutationMeta/updatableServiceLocationMutationFields";

const getServiceLocationTableMeta = () => {
  return {
    columns: [
      {
        Header: "Service Location",
        accessor: "states",
        Cell: ({ value }) => value?.map((d) => d?.state?.name)?.join(", "),
      },
      {
        Header: "Service Category",
        accessor: "service_category",
        Cell: ({ value }) => value?.name,
      },
      {
        Header: "Service Sub-category",
        accessor: "service_sub_category",
        Cell: ({ value }) => value?.name,
      },
      {
        Header: "",
        accessor: "actions",
        options: [
          (defaultValues, hideActions) => (
            <ModalFormOpener
              key="edit"
              submitService={updateServiceLocation}
              fields={updatableServiceLocationMutationFields}
              heading={"Let’s Edit a Service Location!"}
              openerText={() => (
                <span className="btn p-1 btn-outline-primary">
                  <i className="mdi mdi-square-edit-outline text-secondary font-16"></i>{" "}
                  Edit
                </span>
              )}
              openerClassName="submenu_item btn p-0 m-1"
              parentId="table"
              defaultValues={{
                ...defaultValues,
                states: defaultValues.states.map((state) => state.state.id),
                service_category: defaultValues.service_category.id,
                service_sub_category: defaultValues.service_sub_category.id,
              }}
              refreshEvent="refresh_table"
              size="sm"
              onClose={hideActions}
              doNotReset={true}
              successMessage="Service Location updated Successfully!"
              permissions={["super_admin", "admin"]}
            />
          ),
        ],
      },
    ],
    actions: [
      <ModalFormOpener
        key={1}
        submitService={createServiceLocation}
        fields={serviceLocationMutationFields}
        heading={"Create a New Service Location!"}
        openerText={() => <span>+ New Service Location</span>}
        openerClassName="primaryBtn_sm btn btn-primary btn-sm"
        refreshEvent="refresh_table"
        size="sm"
        successMessage="Service Location created Successfully!"
        doNotReset={true}
      />,
    ],
    queryService: getServiceLocations,
  };
};

export default getServiceLocationTableMeta;
