import React from "react";
import ListView from "../../components/listvVew/ListView";
import PageHeader from "../../components/pageHeader/PageHeader";
import { INVENTORY_MANAGEMENT, HOME_PATH } from "../../constants/route";
import getInventoryTableMeta from "../../configs/meta/listMeta/inventoryTableMeta";
import { searchFilterMeta } from "../../configs/meta/listFilterMeta/searchFilterMeta";

const InventoryManagement = () => {
  return (
    <>
      <PageHeader
        title={"Inventory Management"}
        breadCrumbItems={[
          { title: "Dashboard", path: HOME_PATH },
          { title: "Inventories", path: INVENTORY_MANAGEMENT },
        ]}
      />
      <div className="row">
        {/* <TableBuilder/> */}
        <ListView
          title={"Inventory List"}
          filters={searchFilterMeta}
          meta={getInventoryTableMeta()}
        />
      </div>
    </>
  );
};

export default InventoryManagement;
