import moment from "moment";
import ModalFormOpener from "../../../components/actions/ModalFormOpener";
import { cancelBid, getBids, updateBid } from "../../../services/query/bids";
import bidsMutationFields from "../mutationMeta/bidsMutationFields";
import ConfirmationModal from "../../../components/actions/ConfirmationModal";
import upperFirst from "lodash/upperFirst";

const getBidTableMeta = () => {
  return {
    columns: [
      {
        Header: "Order",
        accessor: "order.order_id",
      },
      {
        Header: "Bid Amount",
        accessor: "amount",
      },
      {
        Header: "Bid Status",
        accessor: "status",
        Cell: ({ value }) => upperFirst(value?.split("_")?.join(" ")),
      },
      {
        Header: "Submission Time",
        accessor: "created_at",
        Cell: ({ value }) => moment(value).format("MMM Do YY, h:mm a"),
      },
      {
        Header: "Expiration Date",
        accessor: "expiration_date",
        Cell: ({ value }) => moment(value).format("MMM Do YY, h:mm a"),
      },
      {
        Header: "",
        accessor: "actions",
        options: [
          (defaultValues, hideActions) =>
            defaultValues.status === "pending" && (
              <ModalFormOpener
                key="edit"
                submitService={updateBid}
                fields={bidsMutationFields}
                heading={"Let’s Edit a Bid!"}
                openerText={() => (
                  <span className="btn p-1 btn-outline-primary">
                    <i className="mdi mdi-square-edit-outline text-secondary font-16"></i>{" "}
                    Edit
                  </span>
                )}
                openerClassName="submenu_item btn p-0 m-1"
                parentId="table"
                defaultValues={defaultValues}
                refreshEvent="refresh_table"
                size="sm"
                onClose={hideActions}
                doNotReset={true}
                successMessage="Bid updated Successfully!"
                permissions={["super_admin", "admin"]}
              />
            ),
          (defaultValues, hideActions) =>
            defaultValues.status === "pending" && (
              <ConfirmationModal
                key="cancel"
                submitService={cancelBid}
                defaultValues={defaultValues}
                openerText={() => (
                  <span className="btn p-1 btn-outline-danger">
                    <i className="mdi mdi-trash-can-outline text-danger font-16"></i>{" "}
                    Cancel
                  </span>
                )}
                openerClassName="submenu_item btn p-0 m-1"
                parentId="table"
                refreshEvent="refresh_table"
                size="sm"
                onClose={hideActions}
                doNotReset={true}
                successMessage="Bid Cancelled!"
                submitButtonText="Yes"
                permissions={["super_admin", "admin"]}
              />
            ),
        ],
      },
    ],
    actions: [],
    queryService: getBids,
  };
};

export default getBidTableMeta;
