import React, { useCallback, useContext } from "react";
import FormBuilder from "../../components/form/formBuilder/FormBuilder";
import registerFields from "../../configs/meta/formFilelds/registerFields";
import { createCompany } from "../../services/query/auth";
import logo from "../../assets/images/logo-sm.png";
import backgroundImg from "../../assets/images/p-1.png";
import { Link, useNavigate } from "react-router-dom";
import { LOGIN_PATH } from "../../constants/route";
import NotyfContext from "../../contexts/NotyfContext";

const Register = () => {
  const notyf = useContext(NotyfContext);
  const navigate = useNavigate();
  const handleSubmit = useCallback(
    async (data) => {
      try {
        const res = await createCompany(data);
        if (!res.success) throw res;
        notyf.open({
          type: "success",
          message: "Successfully created account. Login to continue!",
          duration: 5000,
          ripple: false,
          dismissible: true,
          position: {
            x: "center",
            y: "top",
          },
        });
        navigate(LOGIN_PATH);
        return data;
      } catch (error) {
        notyf.open({
          type: "danger",
          message: "Failed to create account!",
          duration: 5000,
          ripple: false,
          dismissible: true,
          position: {
            x: "center",
            y: "top",
          },
        });
        throw error;
      }
    },
    [notyf]
  );

  return (
    <div
      className="auth-page"
      style={{
        backgroundImage: `url(${backgroundImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
      }}
    >
      <div className="container-md">
        <div className="row vh-100 d-flex justify-content-center">
          <div className="col-12 align-self-center">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-8 mx-auto">
                  <div className="card">
                    <div className="card-body p-0 auth-header-box">
                      <div className="text-center p-3">
                        <a href="index.html" className="logo logo-admin">
                          <img
                            src={logo}
                            height={50}
                            alt="logo"
                            className="auth-logo"
                          />
                        </a>
                        <h4 className="mt-3 mb-1 fw-semibold text-white font-18">
                          Let's Create a Company
                        </h4>
                        <p className="text-muted  mb-0">
                          Sign up to continue to Prime Rental.
                        </p>
                      </div>
                    </div>
                    <div className="card-body pt-0">
                      <FormBuilder
                        onSubmit={handleSubmit}
                        fields={registerFields}
                        submitButton={{
                          className: "btn btn-primary mt-2",
                          label: (
                            <>
                              Submit <i className="fa fa-sign-in-alt"></i>
                            </>
                          ),
                          isDisabled: false,
                        }}
                        className="mt-4"
                        doNotReset={true}
                      />
                      <div className="text-muted">
                        <p className="mt-3 d-flex justify-content-between ">
                          <Link to={LOGIN_PATH} className="text-primary ms-2">
                            Already have an Account
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
