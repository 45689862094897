import React from "react";
import ListView from "../../components/listvVew/ListView";
import PageHeader from "../../components/pageHeader/PageHeader";
import { BID_MANAGEMENT, HOME_PATH } from "../../constants/route";
import getBidTableMeta from "../../configs/meta/listMeta/bidTableMeta";
import { searchFilterMeta } from "../../configs/meta/listFilterMeta/searchFilterMeta";

const BidManagement = () => {
  return (
    <>
      <PageHeader
        title={"Bid Management"}
        breadCrumbItems={[
          { title: "Dashboard", path: HOME_PATH },
          { title: "Bids", path: BID_MANAGEMENT },
        ]}
      />
      <div className="row">
        {/* <TableBuilder/> */}
        <ListView
          title={"Bid List"}
          filters={searchFilterMeta}
          meta={getBidTableMeta()}
        />
      </div>
    </>
  );
};

export default BidManagement;
