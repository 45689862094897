import React, { useCallback, useContext, useEffect, useState } from "react";
import NotyfContext from "../../contexts/NotyfContext";
import { eventBus } from "../../services/eventBus";
import Modal from "../modal/Modal";

function ConfirmationModal({
  submitService, // Add submitService prop
  openerText,
  defaultValues,
  openerTextKey,
  openerClassName = "primaryBtn",
  refreshEvent,
  dataLookupQuery = null,
}) {
  const notyf = useContext(NotyfContext);
  const [formLookupData, setFormLookupData] = useState(openerText);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false); // State for delete confirmation


  const performFormDataLookup = useCallback(async () => {
    const data = await dataLookupQuery();
    setFormLookupData(data);
  }, [dataLookupQuery]);

  useEffect(() => {
    if (dataLookupQuery) performFormDataLookup();
  }, [performFormDataLookup, dataLookupQuery]);

  const handleDeleteConfirmation = () => {
    setDeleteConfirmation(true);
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmation(false);
  };

  const handleDelete = async () => {
    try {
      const res = await submitService(defaultValues);
      if(!res.success) throw res
      eventBus.publish(refreshEvent);
      notyf.open({
        type: "success",
        message: "Deleted successfully",
        duration: 5000,
        ripple: false,
        dismissible: true,
        position: {
          x: "center",
          y: "top",
        },
      });
    } catch (error) {
      console.log(error)
      notyf.open({
        type: "danger",
        message: error.error,
        duration: 5000,
        ripple: false,
        dismissible: true,
        position: {
          x: "center",
          y: "top",
        },
      });
    } finally {
      setDeleteConfirmation(false);
    }
  };

  return (
    <div>
      {deleteConfirmation ? (
        <Modal handleModalClose={handleDeleteCancel} style={{height: '100px', width: '400px', borderRadius: '20px'}}>
          <div className="new_agent_modal_header" style={{marginTop: '40px', marginLeft: '2rem'}}>
            <h2>Alert!</h2>
            <p>Are you sure that you want to perform this action?</p>
          </div>
          <div className="d-flex justify-content-end">
            <div className="new_agent_form_container mb-2" style={{marginTop: '20px', marginRight: '2rem'}}>
              <button
                className="btn btn-primary btn-md"
                onClick={handleDelete}
                >
                Yes
              </button>
              <button
                className="btn btn-secondary ms-2 btn-md"
                onClick={handleDeleteCancel}
                >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      ) : (
        <button
          onClick={handleDeleteConfirmation}
          className={`${openerClassName}`}
        >
          {openerTextKey
            ? formLookupData?.[openerTextKey] || "Processing..."
            : openerText()}
        </button>
      )}
      {/* {!deleteConfirmation && (
        <button
          onClick={handleDeleteConfirmation}
          className={`${openerClassName}`}
        >
          {openerText()}
        </button>
      )} */}
    </div>
  );
}

export default ConfirmationModal;
