import inputTypes from "../../../constants/form/inputTypes";
import {  getStatesWithId } from "../../../services/query/company";
import {
  getCategoriesForDropdown,
  getSubCategoriesForDropdown,
} from "../../../services/query/inventory";

const updatableServiceLocationMutationFields = [
  {
    className: "col-6 mb-3",
    inputType: inputTypes.SELECT,
    lookupQuery: getCategoriesForDropdown,
    key: "service_category",
    labelText: "Service Category",
    rules: { required: true },
  },
  {
    className: "col-6 mb-3",
    inputType: inputTypes.SELECT,
    lookupQuery: getSubCategoriesForDropdown,
    key: "service_sub_category",
    labelText: "Service Sub Categories",
    dependencies: ["service_category"],
    rules: { required: true },
    onDependencyValueChange: (
      { service_category },
      key,
      { performLookupQuery }
    ) => {
      performLookupQuery?.({ category: service_category });
    },
  },
  {
    className: "col-12 mb-3",
    inputType: inputTypes.SELECT,
    isMulti: true,
    lookupQuery: getStatesWithId,
    key: "states",
    labelText: "States",
    rules: { required: true },
  },
  {
    className: "col-6 mb-3",
    inputType: inputTypes.SWITCH,
    key: "is_active",
    labelText: "Active",
  },
];

export default updatableServiceLocationMutationFields;
