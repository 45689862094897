import AuthGuard from "../../components/guards/AuthGuard";
import HomeMarkup from "../../components/homeMarkup/HomeMarkup";
import {
  BID_MANAGEMENT,
  FORMS_PATH,
  HOME_PATH,
  INVENTORY_MANAGEMENT,
  LOGIN_PATH,
  REGISTER_PATH,
  MODAL_PATH,
  ORDER_MANAGEMENT,
  TABLES_PATH,
  USER_MANAGEMENT,
  COMPANY_SETTINGS,
  SERVICE_LOCATION_MANAGEMENT,
  WINNED_ORDER,
} from "../../constants/route";
import DashboardLayout from "../../containers/dashboardLayout/DashboardLayout";
import Forms from "../../pages/forms/Forms";
import Login from "../../pages/login/Login";
import Modals from "../../pages/modals/Modals";
import NotFound from "../../pages/notFound/NotFound";
import Register from "../../pages/register/Register";
import BidManagement from "../../pages/tables/BidManagement";
import CompanySettings from "../../pages/tables/CompanySettings";
import InventoryManagement from "../../pages/tables/InventoryManagement";
import OrderManagement from "../../pages/tables/OrderManagement";
import ServiceLocationManagement from "../../pages/tables/ServiceLocationManagement";
import Tables from "../../pages/tables/Tables";
import UserManagement from "../../pages/tables/UserManagement";
import WinnedOrders from "../../pages/tables/WinnedOrders";

const routesMeta = [
  {
    path: HOME_PATH,
    element: (
      <AuthGuard>
        <DashboardLayout children={<HomeMarkup />} />
      </AuthGuard>
    ),
  },
  {
    path: TABLES_PATH,
    element: (
      <AuthGuard>
        <DashboardLayout children={<Tables />} />
      </AuthGuard>
    ),
  },
  {
    path: ORDER_MANAGEMENT,
    element: (
      <AuthGuard>
        <DashboardLayout children={<OrderManagement />} />
      </AuthGuard>
    ),
  },
  {
    path: WINNED_ORDER,
    element: (
      <AuthGuard>
        <DashboardLayout children={<WinnedOrders />} />
      </AuthGuard>
    ),
  },
  {
    path: INVENTORY_MANAGEMENT,
    element: (
      <AuthGuard>
        <DashboardLayout children={<InventoryManagement />} />
      </AuthGuard>
    ),
  },
  {
    path: BID_MANAGEMENT,
    element: (
      <AuthGuard>
        <DashboardLayout children={<BidManagement />} />
      </AuthGuard>
    ),
  },
  {
    path: USER_MANAGEMENT,
    element: (
      <AuthGuard>
        <DashboardLayout children={<UserManagement />} />
      </AuthGuard>
    ),
  },
  {
    path: FORMS_PATH,
    element: (
      <AuthGuard>
        <DashboardLayout children={<Forms />} />
      </AuthGuard>
    ),
  },
  {
    path: COMPANY_SETTINGS,
    element: (
      <AuthGuard>
        <DashboardLayout children={<CompanySettings />} />
      </AuthGuard>
    ),
  },
  {
    path: SERVICE_LOCATION_MANAGEMENT,
    element: (
      <AuthGuard>
        <DashboardLayout children={<ServiceLocationManagement />} />
      </AuthGuard>
    ),
  },
  {
    path: LOGIN_PATH,
    element: <Login />,
  },
  {
    path: REGISTER_PATH,
    element: <Register />,
  },
  {
    path: MODAL_PATH,
    element: (
      <AuthGuard>
        <DashboardLayout children={<Modals />} />
      </AuthGuard>
    ),
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

export default routesMeta;
