import inputTypes from "../../../constants/form/inputTypes";

const userMutationFields = [
  {
    className: "col-12 mb-3",
    inputType: inputTypes.SELECT,
    key: "role",
    labelText: "User Role",
    rules: { required: true },
    placeholder: "Select Role",
    readOnly: true,
    options: [
      {
        label: "Admin",
        value: "admin",
      },
      {
        label: "Driver",
        value: "driver",
      },
    ],
  },
  {
    className: "col-6 mb-3",
    inputType: inputTypes.TEXT,
    key: "first_name",
    labelText: "First Name",
    placeHolder: "Type your first name",
    rules: { required: true },
    dependencies: ["role"],
    onDependencyValueChange: ({ role }, key, { toggleVisibility }) => {
      toggleVisibility("first_name", role !== "admin");
    },
  },
  {
    className: "col-6 mb-3",
    inputType: inputTypes.TEXT,
    key: "last_name",
    labelText: "Last Name",
    placeHolder: "Type your last name",
    rules: { required: true },
    dependencies: ["role"],
    onDependencyValueChange: ({ role }, key, { toggleVisibility }) => {
      toggleVisibility("last_name", role !== "admin");
    },
  },
  {
    className: "col-6 mb-3",
    inputType: inputTypes.TEXT,
    key: "email",
    labelText: "Your email",
    rules: { required: true },
  },
  {
    className: "col-6 mb-3",
    inputType: inputTypes.TEXT,
    key: "username",
    labelText: "Your Username",
    rules: { required: true },
    dependencies: ["role"],
    onDependencyValueChange: ({ role }, key, { toggleVisibility }) => {
      toggleVisibility("username", role !== "admin");
    },
  },
  {
    className: "col-6 mb-3 pe-3",
    inputType: inputTypes.PASSWORD,
    key: "password",
    labelText: "Password",
    rules: { required: true },
    dependencies: ["role"],
    onDependencyValueChange: ({ role }, key, { toggleVisibility }) => {
      toggleVisibility("password", role !== "admin");
    },
  },
  {
    className: "col-6 mb-3",
    inputType: inputTypes.TEXT,
    key: "phone_number",
    labelText: "Phone Number",
    rules: { required: true },
    dependencies: ["role"],
    onDependencyValueChange: ({ role }, key, { toggleVisibility }) => {
      toggleVisibility("phone_number", role !== "admin");
    },
  },
  // {
  //   className: "col-12 mb-3",
  //   // variant: inputVariants.PLAIN,
  //   inputType: inputTypes.CHECKBOX,
  //   key: "agree_with_terms",
  //   labelText: "I agree with",
  //   render: () => (
  //     <a href="#" className="link">
  //       {" "}
  //       Terms & Conditions Policy
  //     </a>
  //   ),
  //   rules: { required: true },
  // },
];

export default userMutationFields;
