import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useFieldArray } from "react-hook-form";
import InputBuilder from "../inputBuilder/inputBuilder";

const ListInput = ({ meta, formInstance }) => {
  const {
    key,
    fields,
    addButtonText = "+ Add New",
    addButtonClassName,
    labelPrefix,
    listItemClassName,
  } = meta;
  const { control } = formInstance;

  const {
    fields: fieldsData,
    append,
    remove,
    move,
  } = useFieldArray({
    control,
    name: key,
  });

  const handleDragEnd = ({ source, destination }) => {
    if (source.index && destination.index)
      move(source.index, destination.index);
  };

  return (
    <DragDropContext onDragEnd={(results) => handleDragEnd(results)}>
      <Droppable id="dropable">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {fieldsData.map((data, index) => {
              const fieldNamePrefix = `${key}[${index}]`;
              return (
                <Draggable
                  draggableId={`item-${index}`}
                  index={index}
                  key={`item-${index}`}
                >
                  {(provided) => (
                    <div
                      key={fieldNamePrefix}
                      className="d-flex justify-content-between align-items-center mb-3"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <span {...provided.dragHandleProps} className="me-2">
                        <i className="fa fa-bars"></i>
                      </span>
                      <span className="w-100">
                        <div
                          className={`list-input-row ${
                            listItemClassName || ""
                          }`}
                        >
                          {!!labelPrefix && (
                            <p className="mt-2 mb-1">{`${labelPrefix} ${
                              index + 1
                            }`}</p>
                          )}
                          {fields.map((field, idx) => (
                            <InputBuilder
                              key={idx}
                              renderLabel={false}
                              meta={{
                                ...field,
                                key: `${fieldNamePrefix}.${field.key}`,
                              }}
                              formInstance={formInstance}
                            />
                          ))}
                        </div>
                      </span>
                      <span
                        onClick={() => {
                          remove(index);
                        }}
                        style={{
                          display: "inline-block",
                          width: 8,
                          padding: "10px",
                          cursor: "pointer",
                        }}
                      >
                        <i className="fa fa-times"></i>
                      </span>
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <span
        type="button"
        onClick={() => append()}
        className={addButtonClassName || "link-primary"}
      >
        {addButtonText}
      </span>
    </DragDropContext>
  );
};

export default ListInput;
