import React, { useState } from 'react'
import './DashboardLayout.css'
import Sidebar from '../../components/sidebar/Sidebar';
import TopBar from '../../components/topbar/TopBar'

const DashboardLayout = ({ children }) => {

  const [enralgeMenu, setEnlargeMenu] = useState(false);

  return (
    <div id="body">
      <div className={`dark-sidebar ${enralgeMenu && 'enlarge-menu'}`}>
        <TopBar setEnlargeMenu={setEnlargeMenu} />
        <Sidebar setEnlargeMenu={setEnlargeMenu} />
        <div className="page-wrapper" style={{ backgroundColor: '#FAFAFB' }}>
          <div className="page-content-tab">
            <div className="container-fluid">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardLayout
