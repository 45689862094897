import endpoints from "../../constants/endpoints";
import { adminApi } from "../client";

export const getInventories = async (params) => {
  const inventories = await adminApi({
    endpoint: endpoints.INVENTORIES,
    method: "get",
    params: { ...params },
  });
  return {
    ...inventories,
    data: inventories.data.map((inventory) => ({
      ...inventory,
      ...(inventory.address ?? {}),
    })),
  };
};

export const createInventories = async (data) => {
  const payload = {
    ...data,
    image: Array.isArray(data.image) ? data.image[0] : data.image,
  };
  return await adminApi({
    endpoint: endpoints.INVENTORIES,
    method: "post",
    data: payload,
  });
};

export const getCategoriesForDropdown = async (params) => {
  const types = await adminApi({
    endpoint: endpoints.CATEGORIES,
    method: "get",
    params: { page_size: 0 },
  });

  return types.data.map((type) => ({ label: type.name, value: type.id }));
};
export const getSubCategoriesForDropdown = async (params) => {
  console.log(params);
  const types = await adminApi({
    endpoint: endpoints.SUB_CATEGORIES,
    method: "get",
    params: { page_size: 0, ...params },
  });

  return types.data.map((type) => ({ label: type.name, value: type.id }));
};

export const getCompanyCategoriesForDropdown = async (params) => {
  const types = await adminApi({
    endpoint: endpoints.COMPANY_CATAGORIES,
    method: "get",
    params: { page_size: 0 },
  });

  return types.data.map((type) => ({ label: type.name, value: type.id }));
};

export const getCompanySubCategoriesForDropdown = async (params) => {
  const types = await adminApi({
    endpoint: endpoints.COMPANY_SUB_CATAGORIES,
    method: "get",
    params: { page_size: 0, ...params },
  });

  return types.data.map((type) => ({ label: type.name, value: type.id }));
};

export const updateInventory = async (data) => {
  const payload = {
    ...data,
    image: Array.isArray(data.image) ? data.image[0] : data.image,
  };
  return await adminApi({
    endpoint: `${endpoints.INVENTORIES}${data.id}/`,
    method: "patch",
    data: payload,
  });
};

export const getInventoriesForDropdown = async (params) => {
  const types = await adminApi({
    endpoint: endpoints.INVENTORIES,
    method: "get",
    params: { page_size: 0, ...params },
  });

  return types.data.map((type) => ({
    label: `${type.service_category?.name} ${type.size}`,
    value: type.id,
  }));
};
