import React, { Fragment, useEffect, useState } from "react";
import "./Sidebar.css";
import sideBarMeta from "../../configs/meta/sideBarMeta";
import Menus from "./menus/Menus";

const Sidebar = ({ setEnlargeMenu }) => {
  const [screenSize, setScreenSize] = useState(undefined);

  useEffect(() => {
    const handleResize = () => setScreenSize(window?.innerWidth);
    window?.addEventListener("resize", handleResize);
    handleResize();

    return () => window?.removeEventListener("resize", handleResize);
  }, [setScreenSize]);

  useEffect(() => {
    screenSize <= 900 ? setEnlargeMenu(true) : setEnlargeMenu(false);
  }, [screenSize, setEnlargeMenu]);

  return (
    <div className="left-sidebar">
      {/* LOGO */}
      <div className="brand">
        <a href="index.html" className="logo">
          {/* <span>
            <img src={logoSm} alt="logo-small" className="logo-sm" />
          </span>
          <span>
            <img src={logo} alt="logo-large" className="logo-lg logo-light" />
            <img src={logo} alt="logo-large" className="logo-lg logo-dark" />
          </span> */}
          <h2 className="text-secondary">Prime Rental</h2>
        </a>
      </div>
      {/*end logo*/}

      <div className="menu-content h-100">
        <div className="menu-body navbar-vertical tab-content">
          <div className="collapse navbar-collapse" id="sidebarCollapse">
            {/* Navigation */}
            <ul className="navbar-nav">
              {sideBarMeta.map((menuGroup, index) => (
                <Fragment key={menuGroup.key}>
                  {menuGroup.menuLabel}
                  <Menus data={menuGroup.menus} />
                </Fragment>
              ))}
              {/*end nav-item*/}
            </ul>
            {/*end navbar-nav-*/}
          </div>
          {/*end sidebarCollapse*/}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
