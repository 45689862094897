import React from "react";
import PageHeader from "../../components/pageHeader/PageHeader";
import { HOME_PATH, COMPANY_SETTINGS } from "../../constants/route";
import FormBuilder from "../../components/form/formBuilder/FormBuilder";
import companySettingsFields from "../../configs/meta/formFilelds/companySettingsFields";

const CompanySettings = () => {
  return (
    <>
      <PageHeader
        title={"Company Settings"}
        breadCrumbItems={[
          { title: "Dashboard", path: HOME_PATH },
          { title: "Settings", path: COMPANY_SETTINGS },
        ]}
      />
      <div className="row">
        <FormBuilder
          onSubmit={(data) => {
            console.log(data);
          }}
          fields={companySettingsFields}
          submitButton={{
            className: "btn btn-primary mt-2",
            label: (
              <>
                Submit <i className="fa fa-sign-in-alt"></i>
              </>
            ),
            isDisabled: false,
          }}
          className="mt-4"
          doNotReset={true}
        />
      </div>
    </>
  );
};

export default CompanySettings;
