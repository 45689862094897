import inputTypes from "../../../constants/form/inputTypes";
import {
  getCompanyCategoriesForDropdown,
  getCompanySubCategoriesForDropdown,
} from "../../../services/query/inventory";

const inventoryMutationFields = [
  {
    className: "col-6 mb-3",
    inputType: inputTypes.SELECT,
    lookupQuery: getCompanyCategoriesForDropdown,
    key: "service_category",
    labelText: "Service Category",
    rules: { required: true },
  },
  {
    className: "col-6 mb-3",
    inputType: inputTypes.SELECT,
    lookupQuery: getCompanySubCategoriesForDropdown,
    key: "service_sub_category",
    labelText: "Service Sub Categories",
    dependencies: ["service_category"],
    rules: { required: true },
    onDependencyValueChange: (
      { service_category },
      key,
      { performLookupQuery }
    ) => {
      performLookupQuery?.({ service_category_id: service_category });
    },
  },
  {
    className: "col-6 mb-3",
    inputType: inputTypes.TEXT,
    key: "approximate_price",
    labelText: "Approximate Price",
    placeHolder: "Approximate Price",
    rules: { required: true },
  },
  {
    className: "col-6 mb-3",
    inputType: inputTypes.TEXT,
    key: "size",
    labelText: "Size",
    placeHolder: "Size",
    rules: { required: true },
  },
  {
    className: "col-12 mb-3",
    inputType: inputTypes.TEXTAREA,
    key: "remarks",
    labelText: "Remarks",
    placeHolder: "Remarks",
    rules: { required: true },
  },
  {
    className: "col-6 mb-3",
    inputType: inputTypes.CHECKBOX,
    key: "is_fixed_price",
    labelText: "Fixed Price",
    placeHolder: "Fixed Price",
    rules: { required: true },
  },
  {
    className: "col-12 mb-3",
    inputType: inputTypes.IMAGE,
    key: "image",
    labelText: "Image",
    placeHolder: "Image",
    rules: { required: true },
  },
];

export default inventoryMutationFields;
