import moment from "moment";
import ModalFormOpener from "../../../components/actions/ModalFormOpener";
import {
  cancelOrder,
  confirmFixedPriceOrder,
  getOrders,
  selectInventory,
} from "../../../services/query/order";
import bidsMutationFields from "../mutationMeta/bidsMutationFields";
import { createBid } from "../../../services/query/bids";
import { orderDriverInventorySelectFields } from "../mutationMeta/orderDriverInventorySelectFields";
import ConfirmationModal from "../../../components/actions/ConfirmationModal";
import upperFirst from "lodash/upperFirst";
import { fixedPriceOrderConfirmFields } from "../mutationMeta/fixedPriceOrderConfirmFields";
const getOrderTableMeta = () => {
  return {
    columns: [
      {
        Header: "Order ID",
        accessor: "order_id",
      },
      {
        Header: "Category",
        accessor: "service_category.name",
      },
      {
        Header: "Sub-category",
        accessor: "service_sub_category.name",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => upperFirst(value?.split("_")?.join(" ")),
      },
      {
        Header: "Created at",
        accessor: "created_at",
        Cell: ({ value }) => moment(value).format("MMM Do YY, h:mm a"),
      },
      {
        Header: "Fixed Price Order",
        accessor: "is_fixed_price_order",
        Cell: ({ value }) => (value ? "Yes" : "No"),
      },
      {
        Header: "Pickup Time",
        accessor: "pickup_time",
        Cell: ({ value }) => moment(value).format("MMM Do YY, h:mm a"),
      },
      {
        Header: "Dropoff Time",
        accessor: "drop_off_time",
        Cell: ({ value }) => moment(value).format("MMM Do YY, h:mm a"),
      },
      {
        Header: "",
        accessor: "actions",
        options: [
          (defaultValues, hideActions) =>
            defaultValues.can_bid &&
            (!defaultValues.is_fixed_price_order ? (
              <ModalFormOpener
                key="edit"
                submitService={createBid}
                fields={bidsMutationFields}
                heading={"Create a Bid"}
                openerText={() => (
                  <span className="btn p-1 btn-outline-primary">
                    <i className="mdi mdi-plus text-secondary font-16"></i>
                    Create Bid
                  </span>
                )}
                openerClassName="submenu_item btn p-0 m-1"
                parentId="table"
                defaultValues={defaultValues}
                refreshEvent="refresh_table"
                size="sm"
                onClose={hideActions}
                doNotReset={true}
                successMessage="Bid created successfully!"
                permissions={["super_admin", "admin"]}
              />
            ) : (
              <ModalFormOpener
                key="confirm_fixed"
                submitService={confirmFixedPriceOrder}
                fields={fixedPriceOrderConfirmFields}
                heading={"Confirm order"}
                openerText={() => (
                  <span className="btn p-1 btn-outline-primary">
                    <i className="mdi mdi-clipboard-check text-secondary font-16"></i>
                    Confirm Order
                  </span>
                )}
                openerClassName="submenu_item btn p-0 m-1"
                parentId="table"
                defaultValues={defaultValues}
                refreshEvent="refresh_table"
                size="sm"
                onClose={hideActions}
                doNotReset={true}
                successMessage="Order confirmed successfully!"
                permissions={["super_admin", "admin"]}
              />
            )),

          (defaultValues, hideActions) =>
            defaultValues.status === "bid_selected" &&
            !defaultValues.inventory && (
              <ModalFormOpener
                key="confirm_bid"
                submitService={selectInventory}
                fields={orderDriverInventorySelectFields}
                heading={"Confirm Bid!"}
                openerText={() => (
                  <span className="btn p-1 btn-outline-primary">
                    <i className="mdi mdi-clipboard-check text-secondary font-16"></i>
                    Confirm Bid
                  </span>
                )}
                openerClassName="submenu_item btn p-0 m-1"
                parentId="table"
                defaultValues={defaultValues}
                refreshEvent="refresh_table"
                size="sm"
                onClose={hideActions}
                doNotReset={true}
                successMessage="Order confirmed!"
                submitButtonText="Submit"
                permissions={["super_admin", "admin"]}
              />
            ),
          (defaultValues, hideActions) =>
            defaultValues.status === "bid_selected" &&
            !defaultValues.inventory && (
              <ConfirmationModal
                key="cancel"
                submitService={cancelOrder}
                defaultValues={defaultValues}
                openerText={() => (
                  <span className="btn p-1 btn-outline-danger">
                    <i className="mdi mdi-trash-can-outline text-danger font-16"></i>{" "}
                    Cancel
                  </span>
                )}
                openerClassName="submenu_item btn p-0 m-1"
                parentId="table"
                refreshEvent="refresh_table"
                size="sm"
                onClose={hideActions}
                doNotReset={true}
                successMessage="Order cancelled!"
                submitButtonText="Yes"
                permissions={["super_admin", "admin"]}
              />
            ),
        ],
      },
    ],
    actions: [],
    queryService: getOrders,
  };
};

export default getOrderTableMeta;
