import endpoints from "../../constants/endpoints";
import { adminApi } from "../client";

export const getBids = async (params) => {
  return await adminApi({
    endpoint: endpoints.BIDS,
    method: "get",
    params: { ...params },
  });
};

export const createBid = async (data) => {
  const payload = {
    order: data.id,
    amount: data.amount,
    expiration_date: data.expiration_date,
    additional_notes: data.additional_notes,
  };
  return await adminApi({
    endpoint: endpoints.BIDS,
    method: "post",
    data: payload,
  });
};

export const updateBid = async (data) => {
  const payload = {
    order: data.order.id,
    amount: data.amount,
    expiration_date: data.expiration_date,
    additional_notes: data.additional_notes,
  };
  return await adminApi({
    endpoint: `${endpoints.INVENTORIES}${data.id}/`,
    method: "patch",
    data: payload,
  });
};

export const cancelBid = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.CANCEL_BID}${data.id}/`,
    method: "get",
  });
};
