import inputTypes from "../../../constants/form/inputTypes";
import { getDriversForDropdown } from "../../../services/query/users";

export const fixedPriceOrderConfirmFields = [
  {
    className: "col-12 mb-3",
    inputType: inputTypes.SELECT,
    lookupQuery: getDriversForDropdown,
    key: "driver",
    labelText: "Driver",
    rules: { required: true },
  },
];
