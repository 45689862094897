import React from "react";
import ListView from "../../components/listvVew/ListView";
import PageHeader from "../../components/pageHeader/PageHeader";
import getUserTableMeta from "../../configs/meta/listMeta/userTableMeta";
import { HOME_PATH, USER_MANAGEMENT } from "../../constants/route";
import { searchFilterMeta } from "../../configs/meta/listFilterMeta/searchFilterMeta";

const UserManagement = () => {
  return (
    <>
      <PageHeader
        title={"User Management"}
        breadCrumbItems={[
          { title: "Dashboard", path: HOME_PATH },
          { title: "Users", path: USER_MANAGEMENT },
        ]}
      />
      <div className="row">
        {/* <TableBuilder/> */}
        <ListView
          title={"User List"}
          filters={searchFilterMeta}
          meta={getUserTableMeta()}
        />
      </div>
    </>
  );
};

export default UserManagement;
