export const HOME_PATH = `/`;
export const LOGIN_PATH = `/login`;
export const REGISTER_PATH = `/register`;
export const TABLES_PATH = `/tables`;
export const FORMS_PATH = `/forms`;
export const MODAL_PATH = `/modals`;
export const ORDER_MANAGEMENT = `/order-management`;
export const WINNED_ORDER = `/winned-order`;
export const BID_MANAGEMENT = `/bid-management`;
export const INVENTORY_MANAGEMENT = `/inventory-management`;
export const COMPANY_SETTINGS = `/company-management`;
export const SERVICE_LOCATION_MANAGEMENT = `/service-location-management`;
export const USER_MANAGEMENT = `/user-management`;
export const NOT_FOUND_PATH = `*`;
