import {
  BID_MANAGEMENT,
  HOME_PATH,
  INVENTORY_MANAGEMENT,
  ORDER_MANAGEMENT,
  SERVICE_LOCATION_MANAGEMENT,
  USER_MANAGEMENT,
  WINNED_ORDER,
} from "../../constants/route";
import { AiFillCarryOut } from "react-icons/ai";

const sideBarMeta = [
  {
    group: "Main",
    key: "main",
    menuLabel: (
      <li className="menu-label mt-0">
        M<span>ain</span>
      </li>
    ),
    menus: [
      {
        path: HOME_PATH,
        title: "Dashboards",
        key: "dashboards",
        icon: <i className="ti ti-home menu-icon"></i>,
      },
      {
        path: USER_MANAGEMENT,
        title: "User Management",
        key: "user",
        icon: <i className="ti ti-user menu-icon"></i>,
      },
      {
        path: SERVICE_LOCATION_MANAGEMENT,
        title: "Service Locations",
        key: "service-locations",
        icon: <i className="ti ti-location menu-icon"></i>,
      },
      {
        path: INVENTORY_MANAGEMENT,
        title: "Inventory Management",
        key: "inventory",
        icon: <i className="fa fa-list-alt menu-icon"></i>,
      },
      {
        path: ORDER_MANAGEMENT,
        title: "Order Management",
        key: "order",
        icon: <i className="fa fa-cart-arrow-down menu-icon"></i>,
      },
      {
        path: WINNED_ORDER,
        title: "Winned Order",
        key: "winned-order",
        icon: <AiFillCarryOut className="menu-icon" />,
      },
      {
        path: BID_MANAGEMENT,
        title: "Bid Management",
        key: "bid",
        icon: <i className="fa fa-gavel menu-icon"></i>,
      },
    ],
  },
];
export default sideBarMeta;
