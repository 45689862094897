import React from "react";

function CheckboxInput({ meta, formInstance, isInvalid }) {
  const { key, rules = {}, labelText } = meta;
  const { register } = formInstance;

  return (
    <>
      <div className="form-check">
        <input
          id={key}
          isInvalid={isInvalid}
          className="form-check-input"
          type="checkbox"
          {...register(key, { ...rules })}
        />
        <label
          className="form-check-label"
          htmlFor={key}
        >
          {labelText}
        </label>
      </div>
    </>
  );
}

export default CheckboxInput;
