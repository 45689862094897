import React from "react";
import ListView from "../../components/listvVew/ListView";
import PageHeader from "../../components/pageHeader/PageHeader";
import { HOME_PATH, ORDER_MANAGEMENT } from "../../constants/route";
import { searchFilterMeta } from "../../configs/meta/listFilterMeta/searchFilterMeta";
import getWinnedOrderTableMeta from "../../configs/meta/listMeta/orderWinsTableMeta";

const WinnedOrders = () => {
  return (
    <>
      <PageHeader
        title={"Winned Orders"}
        breadCrumbItems={[
          { title: "Dashboard", path: HOME_PATH },
          { title: "Orders", path: ORDER_MANAGEMENT },
        ]}
      />
      <div className="row">
        {/* <TableBuilder/> */}
        <ListView
          title={"Winned Order List"}
          filters={searchFilterMeta}
          meta={getWinnedOrderTableMeta()}
        />
      </div>
    </>
  );
};

export default WinnedOrders;
