import inputTypes from "../../../constants/form/inputTypes";

export const searchFilterMeta = [
  {
    inputType: inputTypes.TEXT,
    className: "col-12 mb-3",
    key: "search",
    labelText: "Search",
    placeHolder: "Search....",
  },
];
