const endpoints = {
  INVENTORIES: "inventories/company/inventories/",
  COMPANY_CATAGORIES: "companies/company/services/categories/",
  COMPANY_SUB_CATAGORIES: "companies/company/services/subcategories/",
  CATEGORIES: "services/company/categories/",
  SUB_CATEGORIES: "services/company/sub-categories/",
  LOCATIONS: "locations/admin/locations/",
  LOGIN: "accounts/public/login/",
  USERS: "accounts/company/users/",
  DRIVERS: "accounts/company/drivers/invitations/",
  COMPANY: "companies/public/companies/",
  ORDERS: "orders/company/orders/",
  USER_PROFILE: "accounts/user/profile/",
  SERVICE_LOCATION: "companies/company/service-locations/",
  FILE_UPLOAD: "document-upload/",
  BIDS: "bids/company/bids/",
  ORDER_CONFIRM: "orders/company/order-confirm/",
  FIXED_PRICE_ORDER_CONFIRM: "orders/company/fixed-order-confirm/",
  CANCEL_ORDER: "orders/company/order-cancel/",
  CANCEL_FIXED_PRICE_ORDER: "orders/company/fixed-price-order-cancel/",
  CANCEL_BID: "bids/company/cancel-bid/",
};
export default endpoints;
