import ModalFormOpener from "../../../components/actions/ModalFormOpener";
import {
  createInventories,
  getInventories,
  updateInventory,
} from "../../../services/query/inventory";
import { deleteUser, updateUser } from "../../../services/query/userManagement";
import inventoryMutationFields from "../mutationMeta/inventoryMutationFields";
import updatableInventoryMutationFields from "../mutationMeta/updatableInventoryMutationFields";

const getInventoryTableMeta = () => {
  return {
    columns: [
      {
        Header: "Type",
        accessor: "service_category",
        Cell: ({ value }) => (value.name ? value.name : value),
      },
      {
        Header: "Location",
        accessor: "address",
        Cell: ({ value }) => value.city + ", " + value.state || "-",
      },
      {
        Header: "Approximate Price",
        accessor: "approximate_price",
      },
      {
        Header: "Size",
        accessor: "size",
      },
      {
        Header: "Latitude",
        accessor: "latitude",
        Cell: ({ value }) => value || "-",
      },
      {
        Header: "Longitude",
        accessor: "longitude",
        Cell: ({ value }) => value || "-",
      },
      {
        Header: "Last delivered date",
        accessor: "last_delivered_date",
        Cell: ({ value }) => value || "-",
      },
      {
        Header: "Company",
        accessor: "company",
        Cell: ({ value }) => value.name || "-",
      },
      {
        Header: "",
        accessor: "actions",
        options: [
          (defaultValues, hideActions) => (
            <ModalFormOpener
              key="edit"
              submitService={updateInventory}
              fields={updatableInventoryMutationFields}
              heading={"Let’s Edit an inventory!"}
              openerText={() => (
                <span className="btn p-1 btn-outline-primary">
                  <i className="mdi mdi-square-edit-outline text-secondary font-16"></i>{" "}
                  Edit
                </span>
              )}
              openerClassName="submenu_item btn p-0 m-1"
              parentId="table"
              defaultValues={{
                ...defaultValues,
                service_category: defaultValues.service_category.id,
                service_sub_category: defaultValues.service_sub_category.id,
              }}
              refreshEvent="refresh_table"
              size="sm"
              onClose={hideActions}
              doNotReset={true}
              successMessage="Inventory updated Successfully!"
              permissions={["super_admin", "admin"]}
            />
          ),
        ],
      },
    ],
    actions: [
      <ModalFormOpener
        key={1}
        submitService={createInventories}
        fields={inventoryMutationFields}
        heading={"Create a New Inventory!"}
        openerText={() => <span>+ New Inventory</span>}
        openerClassName="primaryBtn_sm btn btn-primary btn-sm"
        refreshEvent="refresh_table"
        size="sm"
        successMessage="Inventory created Successfully!"
        doNotReset={true}
      />,
    ],
    queryService: getInventories,
  };
};

export default getInventoryTableMeta;
